import axios from 'axios'

import * as actions from '../actionTypes/challengesActionTypes'
import common from '../common'
import safePick from '../utils/safePick'

const refreshChallengeList = challengeList => ({
  type: actions.UPDATE_LIST_OF_CHALLENGES,
  challengeList,
})

const deleteChallengeAction = challengeId => ({
  type: actions.DELETE_CHALLENGE,
  challengeId,
})

export const setCurrentChallenge = ID => ({
  type: actions.SET_CURRENT_CHALLENGE,
  ID,
})

export const updateCurrentChallengeData = challenge => ({
  type: actions.UPDATE_CURRENT_CHALLENGE_DATA,
  challenge,
})

export const updateCurrentStepsToApprove = stepsToApprove => ({
  type: actions.UPDATE_CURRENT_STEPS_TO_APPROVE,
  stepsToApprove,
})

export const updateCurrentChallengeUserSteps = steps => ({
  type: actions.UPDATE_CURRENT_STEPS,
  steps,
})

export const displayedBySeeAllButton = displayedBySeeAllButton => ({
  type: actions.CLICKED_FROM_SEE_ALL_BUTTON,
  displayedBySeeAllButton,
})

export const setCurrentChallengeTasks = tasks => ({
  type: actions.SET_CURRENT_CHALLENGE_TASKS,
  tasks,
})

export const setCurrentChallengeTaskIndex = index => ({
  type: actions.SET_CURRENT_CHALLENGE_TASK_INDEX,
  index,
})

export const setCurrentChallengeSubmitId = currentChallengeSubmitId => ({
  type: actions.SET_CURRENT_CHALLENGE_SUBMIT_ID,
  currentChallengeSubmitId,
})

export const updateSelectedTask = (challengeTask, challengeTaskIndex) => ({
  type: actions.UPDATE_SELECTED_TASK,
  challengeTask,
  challengeTaskIndex,
})

const setChallengeStepTypes = stepTypes => ({
  type: actions.SET_CHALLENGES_STEP_TYPES,
  stepTypes,
})

export const setChallengeLocations = locations => ({
  type: actions.SET_CHALLENGES_LOCATIONS,
  locations,
})

const setChallengesError = errorMessage => ({
  type: actions.SET_CHALLENGES_ERROR,
  error: errorMessage || 'Unknown error occured',
})

export const clearChallengesError = () => ({
  type: actions.CLEAR_CHALLENGES_ERROR,
})

export const saveChallenge =
  (challenge, challengeImage, handleSecondPageContent) =>
  async (dispatch, getState) => {
    try {
      if (challengeImage) {
        // upload image
        const fd = new FormData()
        fd.append('File', challengeImage, challengeImage.name)

        const imageURL = await axios
          .post(`${common.baseUrl}/Challenges/Upload/0`, fd, {
            headers: {
              Authorization: `${localStorage.getItem(
                'TokenType'
              )} ${localStorage.getItem('AccessToken')}`,
            },
          })
          .then(response => response.data)

        challenge.ImageURL = imageURL
      }

      const url = challenge.ID
        ? `${common.baseUrl}/Challenges/Update`
        : `${common.baseUrl}/Challenges/Add`

      const req = challenge.ID ? axios.put : axios.post

      await req(url, challenge, {
        headers: {
          Authorization: `${localStorage.getItem(
            'TokenType'
          )} ${localStorage.getItem('AccessToken')}`,
        },
      }).then(async () => {
        await axios
          .get(`${common.baseUrl}/Challenges/GetChallengesWhereAdmin`, {
            headers: {
              Authorization: `${localStorage.getItem(
                'TokenType'
              )} ${localStorage.getItem('AccessToken')}`,
            },
          })
          .then(async response => {
            dispatch(refreshChallengeList(response.data.Challenges))
            dispatch(
              setCurrentChallenge(
                challenge.ID || response.data.Challenges.find(x => true)?.ID // edited or added
              )
            )
            handleSecondPageContent('ChallengeDetails')

            if (challenge.ID) {
              dispatch(getAllChallengeInfo(challenge.ID))
            }
          })
      })
    } catch (error) {
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setChallengesError(errorMessage))
    }
  }

export const updateCurrentChallengeTask =
  (
    challengeTask,
    challengeTaskIndex,
    challengeTaskImage,
    handleThirdPageContent
  ) =>
  async (dispatch, getState) => {
    try {
      if (challengeTaskImage) {
        // upload image
        const fd = new FormData()
        fd.append('File', challengeTaskImage, challengeTaskImage.name)

        const imageURL = await axios
          .post(`${common.baseUrl}/Challenges/Upload/0`, fd, {
            headers: {
              Authorization: `${localStorage.getItem(
                'TokenType'
              )} ${localStorage.getItem('AccessToken')}`,
            },
          })
          .then(response => response.data)

        challengeTask.ImageURL = imageURL
      }

      dispatch(updateSelectedTask(challengeTask, challengeTaskIndex))
      handleThirdPageContent('AddChallengeTask')
    } catch (error) {
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setChallengesError(errorMessage))
    }
  }

export const getAllChallengeInfo =
  challengeId => async (dispatch, getState) => {
    await axios
      .get(`${common.baseUrl}/Challenges/GetChallengeByID/${challengeId}`, {
        headers: {
          Authorization: `${localStorage.getItem(
            'TokenType'
          )} ${localStorage.getItem('AccessToken')}`,
        },
      })
      .then(async response => {
        dispatch(updateCurrentChallengeData(response.data))
      })
  }

export const getChallengeStepsToApprove =
  challengeId => async (dispatch, getState) => {
    await axios
      .get(
        `${common.baseUrl}/Challenges/GetStepSubmitsToApprove/${challengeId}`,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )
      .then(async response => {
        dispatch(updateCurrentStepsToApprove(response.data))
        dispatch(displayedBySeeAllButton(false))
      })
  }

export const getAllChallengeStepSubmitsToApprove =
  () => async (dispatch, getState) => {
    await axios
      .get(`${common.baseUrl}/Challenges/GetAllStepSubmitsToApprove`, {
        headers: {
          Authorization: `${localStorage.getItem(
            'TokenType'
          )} ${localStorage.getItem('AccessToken')}`,
        },
      })
      .then(async response => {
        dispatch(updateCurrentStepsToApprove(response.data))
        dispatch(displayedBySeeAllButton(true))
      })
  }

export const getUserChallengeSubmits =
  (challengeId, userId, businessPinId) => async (dispatch, getState) => {
    await axios
      .get(
        `${common.baseUrl}/Challenges/GetUserSubmits/${challengeId}/${userId}/${businessPinId}`,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )
      .then(async response => {
        dispatch(updateCurrentChallengeUserSteps(response.data))
      })
  }

export const getChallengeStepTypes = () => async (dispatch, getState) => {
  await axios
    .get(`${common.baseUrl}/Challenges/GetChallengeStepTypes`, {
      headers: {
        Authorization: `${localStorage.getItem(
          'TokenType'
        )} ${localStorage.getItem('AccessToken')}`,
      },
    })
    .then(async response => {
      dispatch(setChallengeStepTypes(response.data.Types))
    })
}

export const getChallengeLocations =
  challengeId => async (dispatch, getState) => {
    await axios
      .get(
        `${common.baseUrl}/Challenges/GetChellengeBusinessPins/${challengeId}?skip=0&take=100`,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )
      .then(async response => {
        dispatch(setChallengeLocations(response.data.BusinessPins))
      })
  }

export const updateShownChallenges = () => async (dispatch, getState) => {
  await axios
    .get(`${common.baseUrl}/Challenges/GetChallengesWhereAdmin`, {
      headers: {
        Authorization: `${localStorage.getItem(
          'TokenType'
        )} ${localStorage.getItem('AccessToken')}`,
      },
    })
    .then(async response => {
      dispatch(refreshChallengeList(response.data.Challenges))
    })
}

export const deleteChallenge =
  (challengeId, handleSecondPageContent) => async (dispatch, getState) => {
    await axios
      .delete(`${common.baseUrl}/Challenges/Delete/${challengeId}`, {
        headers: {
          Authorization: `${localStorage.getItem(
            'TokenType'
          )} ${localStorage.getItem('AccessToken')}`,
        },
      })
      .then(() => {
        dispatch(deleteChallengeAction(challengeId))
        dispatch(updateShownChallenges())
        handleSecondPageContent('ChallengeDetails')
      })
  }

export const approveChallengeStepSubmit =
  challengeStepSubmitID => async (dispatch, getState) => {
    try {
      await axios.put(
        `${common.baseUrl}/Challenges/ApproveChallengeStepSubmit`,
        {
          challengeStepSubmitID,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )

      dispatch(setChallengeStepSubmitAsApproved(challengeStepSubmitID))
    } catch (error) {
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setChallengesError(errorMessage))
    }
  }

const setChallengeStepSubmitAsApproved = challengeStepSubmitID => ({
  type: actions.SET_CHALLENGE_STEP_SUBMIT_AS_APPROVED,
  challengeStepSubmitID,
})

export const approveChallengeStepSubmits =
  challengeID => async (dispatch, getState) => {
    try {
      await axios.put(
        `${common.baseUrl}/Challenges/ApproveChallengeStepSubmits/${challengeID}`,
        {},
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )

      dispatch(setChallengeStepSubmitsAsApproved(challengeID, null))
    } catch (error) {
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setChallengesError(errorMessage))
    }
  }

export const approveMultipleChallengeStepSubmits =
  challengeSubmits => async (dispatch, getState) => {
    try {
      await axios.put(
        `${common.baseUrl}/Challenges/ApproveMultipleChallengeStepSubmits`,
        challengeSubmits,
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )

      dispatch(setChallengeStepSubmitsAsApproved(null, challengeSubmits))
    } catch (error) {
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setChallengesError(errorMessage))
    }
  }

const setChallengeStepSubmitsAsApproved = (
  challengeId,
  challengeSubmitsIds
) => ({
  type: actions.SET_CHALLENGE_STEP_SUBMITS_AS_APPROVED,
  challengeId,
  challengeSubmitsIds,
})

export const rejectChallengeStepSubmit =
  (challengeStepSubmitID, rejectionMessage) => async (dispatch, getState) => {
    try {
      await axios.put(
        `${common.baseUrl}/Challenges/RejectChallengeStepSubmit`,
        {
          challengeStepSubmitID,
          rejectionMessage,
        },
        {
          headers: {
            Authorization: `${localStorage.getItem(
              'TokenType'
            )} ${localStorage.getItem('AccessToken')}`,
          },
        }
      )

      dispatch(
        setChallengeStepSubmitRejectionMessage(
          challengeStepSubmitID,
          rejectionMessage
        )
      )
    } catch (error) {
      const errorMessage = safePick(error, 'response', 'data', 'Errors', [0])
      dispatch(setChallengesError(errorMessage))
    }
  }

const setChallengeStepSubmitRejectionMessage = (
  challengeStepSubmitID,
  rejectionMessage
) => ({
  type: actions.SET_CHALLENGE_STEP_SUBMIT_REJECTION_MESSAGE,
  challengeStepSubmitID,
  rejectionMessage,
})
