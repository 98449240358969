const IS_PRODUCTION = process.env.NODE_ENV === 'production'

let baseUrl = null,
  imagesBaseUrl = null,
  videosBaseUrl = null

if (IS_PRODUCTION) {
  /* Prod */
  baseUrl = 'https://api.pinapp.online'
  imagesBaseUrl = 'https://media.pinapp.online'
  videosBaseUrl = 'https://media.pinapp.online'
} else {
  /* Local */
  baseUrl = 'http://localhost:61885'
  imagesBaseUrl = 'http://localhost:61885/uploads'
  videosBaseUrl = 'http://localhost:61885/uploads'
}

export default {
  baseUrl,
  imagesBaseUrl,
  videosBaseUrl,
}
